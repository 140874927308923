<!--
 * @Description:
 * @Author: wendan
 * @Date: 2022-03-04 19:08:29
 * @LastEditTime: 2022-03-09 10:04:42
 * @LastEditors: wendan
 * @Reference:
-->
<template>
  <div class="wrapper">
    <div class="con-form">
      <h4>未接听电话记录</h4>
      <el-form ref="form" :model="form" label-width="150px">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item label="老人姓名">
              <el-input v-model="form.name" placeholder="请输入老人姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="老人电话">
              <el-input v-model="form.tel" placeholder="请输入老人电话" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="坐席姓名">
              <el-input v-model="form.callerName" placeholder="请输入坐席姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="呼叫类型">
              <el-select v-model="form.type" placeholder="请选择呼叫类型">
                <el-option label="呼入" value="1" />
                <el-option label="呼出" value="2" />
                <el-option label="呼叫分机" value="3" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="日期时间：">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="人员级别">
              <el-select v-model="form.personLevel" placeholder="人员级别">
                <el-option v-for="item in personLevelList" :key="item.dictPidVal" :label="item.dictDesc" :value="item.dictPidVal"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="4">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="btn">
      <!-- <el-button type="success" icon="el-icon-plus">导出记录</el-button> -->
    </div>
    <div>
      <vxe-table
        :key="Math.random()"
        border
        resizable
        auto-resize
        show-header-overflow
        show-overflow
        highlight-hover-row
        :loading="loading"
        :data="recordsList"
      >
        <!-- <vxe-table-column type="checkbox" align="center" width="100"></vxe-table-column> -->
        <vxe-table-column field="connectionid" title="话务流水号" min-width="120" align="center" />
        <vxe-table-column field="pubOldPersonName" title="老人姓名" min-width="120" align="center" />
        <vxe-table-column field="telephone" title="老人电话" min-width="120" align="center" />
        <vxe-table-column field="calltime" title="呼叫时间" min-width="150" align="center" />
        <vxe-table-column field="hanguptime" title="挂机时间" min-width="150" align="center" />
        <vxe-table-column field="queueflag" title="排队标志" min-width="100" align="center" />
        <vxe-table-column field="empid" title="呼损归属坐席" min-width="100" align="center" />
        <!--<vxe-table-column title="操作" width="200" fixed="right">-->
        <!--  <template>-->
        <!--    <vxe-button type="text" status="primary" content="查看通话记录" />-->
        <!--  </template>-->
        <!--</vxe-table-column>-->
      </vxe-table>
      <div class="page">
        <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      form: {
        name: '',
        tel: '',
        type: '',
        date: '',
        callerName: ''
        // personLevel:'',

      },
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50],
        perfect: true
      },
      recordsList: [
      ]
    }
  },
  computed: {
    ...mapState([
      'layouts'
    ])
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions([
      'getMissCallList',
      'getSysDictServe'

    ]),
    initView() {
      this.getLists()
      this.getDicList()
    },
    getDicList() {
      this.getSysDictList('personLevel', 'personLevelList') // 人员级别
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    getLists() {
      this.loading = true
      this.getMissCallList({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          beginTime: this.form.date[0],
          endTime: this.form.date[1],
          callerName: this.form.callerName,
          pubOldPersonName: this.form.name,
          telephone: this.form.tel,
          calltype: this.form.type
          // personLevel:this.form.personLevel

        }
      }).then(res => {
        if (res.code == 200) {
          this.recordsList = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    onSubmit() {
      this.tablePage.currentPage = 1
      this.getLists()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
      padding: 20px;
  }
</style>
